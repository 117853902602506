import moment from 'moment-timezone'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'
import ga from './GoogleAnalysize.js'
import commonUtil from './CommonTool.js'
// 引入缓存层
import store from '../store/index.js'
const wsdress = "wss://ext.qianclouds.com/api";
const address = "https://ext.qianclouds.com/api"
// const wsdress = "ws://localhost";
// const address = "http://localhost"
const TIME_ZONE = moment.tz.guess();
const xappl = "X-APPL";
const xide = "X-IDE";
// 全部接口
export default {
	async signIn(email, password, kaptchaKey, kaptchaValue) {
		let url = address + "/user/signin";
		let data = {
			email: email,
			password: password,
			timezone: TIME_ZONE
		}
		if (kaptchaKey != null) {
			data.kaptchaKey = kaptchaKey;
			data.kaptchaValue = kaptchaValue;
		}
		let result = await asyncPostForm(url, data, null);
		store.saveAuthorization(result.data);
		return;
	},

	async signUp(email, password) {
		let url = address + "/user/signup";
		let data = {
			email: email,
			password: password,
			timezone: TIME_ZONE
		}
		let result = await asyncPostForm(url, data, null);
		store.saveAuthorization(result.data);
		return;
	},

	async sendSmsCode(phoneNumber) {
		let url = address + "/user/sms/bind";
		let data = {
			phoneNumber: phoneNumber
		}
		await asyncPostForm(url, data, null);
		return;
	},
	async resetPhoneCode(phoneNumber, code) {
		let url = address + "/user/info/reset/phone";
		let data = {
			phoneNumber: phoneNumber,
			code: code
		}
		await asyncPostForm(url, data, null);
		return;
	},
	async kaptcha(key) {
		let url = address + "/user/kaptcha?key=" + key;
		let result = await asyncGet(url, {}, null);
		return result;
	},

	async signOut() {
		let url = address + "/user/logout";
		await asyncPostForm(url, {}, null);
		store.clear();
		return;
	},

	async forgetSend(email, kaptchaKey, kaptchaValue) {
		let url = address + "/user/password/forgot_send";
		let data = {
			email: email,
			kaptchaKey: kaptchaKey,
			kaptchaValue: kaptchaValue
		}
		await asyncPostForm(url, data, null);
		return;
	},
	async forgetReset(email, password, code) {
		let url = address + "/user/password/reset";
		let data = {
			email: email,
			password: password,
			code: code
		}
		await asyncPostForm(url, data, null);
		return;
	},
	//////////////////////////////登录分界线/////////////////////////////////////////////

	async getUserInfo() {
		let url = address + "/user/info";
		let result = await asyncGet(url);
		store.setUserInfo(result);
		return result.data;
	},

	async resetPassword(oldpwd, newpwd) {
		let url = address + "/user/info/reset/password";
		let data = {
			oldPassword: oldpwd,
			newPassword: newpwd
		}
		await asyncPostForm(url, data, null);
		return;
	},
	async resetHeadimg(imageFile) {
		let url = address + "/user/info/reset/headimg";
		let result = await asyncUploadFile(url, imageFile, null)
		return result.data;
	},

	async discountCodeVerify(orderId, discountCode) {
		let url = address + "/user/order/discount_verify";
		let data = {
			orderId: orderId,
			discountCode: discountCode
		}
		let result = await asyncPostForm(url, data, null)
		return result.data;
	},

	async createRegisterVipGift() {
		let url = address + "/user/order/create_register_vip";
		await asyncPostForm(url, null, null)
	},

	async createOrder(giftId, giftName, giftNumber) {
		let url = address + "/user/order/create";
		let data = {
			giftId: giftId,
			giftNumber: giftNumber,
			giftName: giftName
		}
		let result = await asyncPostForm(url, data, null)
		return result.data;
	},

	async alipay(orderId, giftName) {
		let url = address + "/user/order/alipay";
		let data = {
			orderId: orderId,
			giftName: giftName
		}
		let result = await asyncPostForm(url, data, null)
		return result.data;
	},

	async orderCheck(orderId) {
		let url = address + "/user/order/check";
		let data = {
			orderId: orderId
		}
		let result = await asyncPostForm(url, data, null)
		return result.data;
	},

	async orderList() {
		let url = address + "/user/order/list";
		let result = await asyncGet(url, null, null)
		return result.data;
	},
  // 留言板
  async userSaveMessage(message){
    let url = address + "/user/message/write";
		let data = {
			message: message
		}
		let result = await asyncPostForm(url, data, null);
		return result;
  },
  
	/* drive.auth  drives */
	async authNewDrive(type, id) {
		let url = address + "/drives/auth/new";
		let data = {
			type: type,
			id: id
		}
		let result = await asyncPostForm(url, data, null);
		store.setDrives(null);
		return result;
	},

async authSecretDrive(type, id, appKey, appSecret) {
	let url = address + "/drives/auth/new_secret";
	let data = {
		type: type,
		id: id,
    appKey: appKey,
		appSecret: appSecret
	}
	
	let result = await asyncPostForm(url, data, null);
	store.setDrives(null);
	return result;
},
async authCookieDrive(type, id, cookie) {
	let url = address + "/drives/auth/new_cookie";
	let data = {
		type: type,
		id: id,
    cookie: cookie
	}
	
	let result = await asyncPostForm(url, data, null);
	store.setDrives(null);
	return result;
},

	createNewDriveWss() {
		return new WebSocket(wsdress + "/drives/change/" + store.getAuthorization());
	},

	/* drive.manager */
	async drives(refresh) {
		if (!refresh && store.getDrives() != null) {
			return store.getDrives();
		}
		let url = address + "/drives/manager/clouds";
		let result = await asyncGet(url);
		store.setDrives(result);
		return result;

	},
	async driveAdd(userId) {
		let url = address + "/drives/manager/add";
		let data = {
			userId: userId
		}
		store.setDrives(null);
		let result = await asyncPostForm(url, data, null);
		return result;
	},
	async driveDel(cloudId) {
		let url = address + "/drives/manager/del/" + cloudId;
		store.setDrives(null);
		let result = await asyncPostForm(url, {}, null);
		return result;
	},
	async driveUpdateName(cloudId, newName) {
		let url = address + "/drives/manager/update/name";
		let data = {
			authId: cloudId,
			newName: newName
		};
		let result = await asyncPostForm(url, data, null);
		return result;
	},
	async driveBaiduVip(cloudId) {
		let url = address + "/drives/manager/baidu.vip/" + cloudId;
		let result = await asyncGet(url, null, null);
		return result;
	},
	/* drive.file */
	async driveAccount(authId, isRefresh) {
		let url = address + "/drives/" + authId + "/account";
		if (!isRefresh && store.getDriveAccount(authId) != null) {
			return store.getDriveAccount(authId);
		}
		let result = await asyncGet(url);
		store.setDriveAccount(authId, result);
		return result;
	},

	async driveItemsRefresh(authId, folderId, folderPath, isFirst, num) {
		store.setDriveFiles(authId, folderId, null);
		return this.driveItems(authId, folderId, folderPath, isFirst, num);
	},

	async driveItems(authId, folderId, folderPath, isFirst, num) {
		let old = store.getDriveFiles(authId, folderId);
		if (isFirst) {
			// 加载缓存
			if (old != null) {
				return old;
			}
			// 请求
			else {
				let url = address + "/drives/" + authId + "/items/" + folderId;
				let data = {
					isFirst: isFirst,
					num: num,
          folderPath: folderPath
				}
				let result = await asyncPostForm(url, data, null);
				store.setDriveFiles(authId, folderId, result);
				return result;
			}
		} else {
			if (old.data.end) {
				return old;
			}
			let url = address + "/drives/" + authId + "/items/" + folderId;
			let data = {
				isFirst: isFirst,
				num: num,
        folderPath: folderPath
			}
			let result = await asyncPostForm(url, data, null);
			let copy = JSON.parse(JSON.stringify(result));
			let newData = old.data.items.concat(result.data.items);
			result.data.items = newData;
			store.setDriveFiles(authId, folderId, result);
			return copy;
		}
	},

	async driveFolderRefresh(authId, folderId) {
		let folderKey = getFolderKey(folderId);
		store.setDriveFiles(authId, folderKey, null);
		return this.driveFolders(authId, folderId);
	},

	async driveFolders(authId, folderId) {
		let folderKey = getFolderKey(folderId);
		if (store.getDriveFiles(authId, folderKey) != null) {
			return store.getDriveFiles(authId, folderKey);
		}
		let url = address + "/drives/" + authId + "/items/" + folderId + "/folders";
		let result = await asyncGet(url);
		store.setDriveFiles(authId, folderKey, result);
		return result;
	},

	async driveSpecialFolders(authId, folderId) {
		let folderKey = getFolderKey(folderId);
		if (store.getDriveFiles(authId, folderKey) != null) {
			return store.getDriveFiles(authId, folderKey);
		}
		let url = address + "/drives/" + authId + "/items/" + folderId + "/specialFolders";
		let result = await asyncGet(url);
		store.setDriveFiles(authId, folderKey, result);
		return result;
	},

	async driveMkdir(authId, folderId, name) {
		let url = address + "/drives/" + authId + "/" + folderId + "/mkdir";
		let data = {
			name: name
		}
		let result = await asyncPostForm(url, data);

		// 添加folderkey中缓存
		let folderKey = getFolderKey(folderId);
		let storeFolderKeyResult = store.getDriveFiles(authId, folderKey);
		if (storeFolderKeyResult) {
			let data1 = storeFolderKeyResult.data.items;
			data1.unshift(result.data);
			store.setDriveFiles(authId, folderKey, storeFolderKeyResult);
		}

		// 添加folderI中缓存
		let storeResult = store.getDriveFiles(authId, folderId);
		if (storeResult) {
			let data = storeResult.data.items;
			data.unshift(result.data);
			store.setDriveFiles(authId, folderId, storeResult);
		}
		return result;
	},
	async driveItemDelete(authId, itemId, parentId, callback) {
		let url = address + "/drives/" + authId + "/delete/" + itemId;
		let result = await asyncPostForm(url, null, callback);

		// 删除folderkey中缓存
		let folderKey = getFolderKey(parentId);
		let storeFolderKeyResult = store.getDriveFiles(authId, folderKey);
		if (storeFolderKeyResult) {
			let data1 = storeFolderKeyResult.data.items;
			for (var i = 0; i < data1.length; i++) {
				let storeItem = data1[i];
				if (storeItem.id == itemId) {
					data1.splice(i, 1);
					break
				}
			}
			store.setDriveFiles(authId, folderKey, storeFolderKeyResult);
		}

		// 删除folderI中缓存
		let storeResult = store.getDriveFiles(authId, parentId);
		if (storeResult) {
			let data = storeResult.data.items;
			for (var j = 0; j < data.length; j++) {
				let storeItem = data[j];
				if (storeItem.id == itemId) {
					data.splice(j, 1);
					break
				}
			}
			store.setDriveFiles(authId, parentId, storeResult);
		}


		return result;
	},

	/* task.manager */
	async taskList(driveId) {
		let url = address + "/task/manager/list/" + driveId;
		let result = await asyncGet(url);
		return result;
	},


	/* task.transfer */
	async transferCreate(taskName, sourceDriveId, sourceItems, destinationDriveId, destinationItem, actionModel,
		timerModel) {
		let url = address + "/task/transfer/add";
		timerModel.timezone = TIME_ZONE
		let data = {
			name: taskName,
			sourceDriveId: sourceDriveId,
			sourceItems: sourceItems,
			destinationDriveId: destinationDriveId,
			destinationItem: destinationItem,
			actionModel: actionModel,
			timerModel: timerModel
		}
		let result = await asyncPostJson(url, data, null);
		store.setTransferList(null)
		return result;
	},

	async transferCreateName() {
		let url = address + "/task/transfer/name";
		let result = await asyncGet(url, null);
		return result;
	},

	async transferGet(jobId) {
		let url = address + "/task/transfer/get/" + jobId;
		let result = await asyncGet(url, null);
		return result;
	},

	async transferGetSession(jobId, sessionId) {
		let url = address + "/task/transfer/get/" + jobId + "/" + sessionId;
		let result = await asyncGet(url, null);
		return result;
	},

	async transferQuit(sessionId) {
		let url = address + "/task/transfer/quit";
		let data = {
			sessionId: sessionId,
		}
		let result = await asyncPostForm(url, data, null);
		return result;
	},
	async transferRetry(sessionDoneId, ids) {
		let url = address + "/task/transfer/retry";
		let data = {
			sessionDoneId: sessionDoneId,
			ids: ids
		}
		let result = await asyncPostForm(url, data, null);
		return result;
	},
	async transferUpdate(jobId, action) {
		let url = address + "/task/transfer/update";
		let data = {
			id: jobId,
			actionModel: action
		}
		let result = await asyncPostJson(url, data, null);
		return result;
	},

	async transferList() {
		let url = address + "/task/transfer/list";
		let result = await asyncPostJson(url, null, null);
		return result;
	},
	async transferGetTask(id) {
		let url = address + "/task/transfer/getTask";
		let data = {
			id: id,
		}
		let result = await asyncPostJson(url, data, null);
		return result;
	},
	async transferExecute(jobId) {
		let url = address + "/task/transfer/execute";
		let data = {
			jobId: jobId,
			timezone: TIME_ZONE
		}
		let result = await asyncPostForm(url, data, null);
		return result;
	},

	async transferProgress(sessionId, isRefresh) {
		if (!isRefresh) {
			let progress = store.getTransferTaskProgress(sessionId);
			if (progress != null) {
				return progress;
			}
		}
		let url = address + "/task/transfer/progress?sessionId=" + sessionId;
		let result = await asyncGet(url, null);
		store.setTransferTaskProgress(sessionId, result);
		return result;
	},

	async transferDelete(id) {
		let url = address + "/task/transfer/del";
		let data = {
			id: id
		}
		let result = await asyncPostJson(url, data, null);
		return result;
	},

	async transferCancel(sessionId) {
		let url = address + "/task/transfer/stop";
		let data = {
			sessionId: sessionId
		}
		let result = await asyncPostForm(url, data, null);
		return result;
	},

	// 睡眠
	async sleep(time) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(time)
			}, time * 1000)
		})
	},

	// 睡眠
	async sleepMilli(time) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(time)
			}, time)
		})
	},
}

function getFolderKey(folderId) {
	return "Folder_" + folderId;
}

// GET请求
async function asyncGet(url) {
	let body = {
		method: "GET",
		url: url
	}
	return await doRequest(md5(url), body);
}

// POST JSON 常规请求
async function asyncPostJson(url, data, callback) {
	let body = {
		method: "POST",
		url: url,
		data: data
	}
	let values = "";
	for (const key in data) {
		values += data[key];
	}
	return await doRequest(md5(values), body, callback);
}

// POST form 常规请求
async function asyncPostForm(url, data, callback) {
	let form = new FormData();
	let values = "";
	for (const key in data) {
		values += data[key];
		form.append(key, data[key])
	}
	let body = {
		method: "POST",
		headers: {
			'content-type': 'application/x-www-form-urlencoded',
		},
		url: url,
		data: form
	}
	return await doRequest(md5(values), body, callback);
}

async function asyncUploadFile(url, file, callback) {
	let form = new FormData();
	form.append("file", file);
	let body = {
		method: "POST",
		headers: {
			'content-type': 'multipart/form-data',
		},
		url: url,
		data: form
	}

	return await doRequest(md5(url), body, callback);
}

// 发送抽象请求
async function doRequest(ideMd5, body, callback) {
	let authToken = store.getAuthorization();
	if (!body.headers) {
		body.headers = {
			Authorization: authToken
		}
	} else if (authToken) {
		body.headers.Authorization = authToken;
	}

	body.headers[xappl] = salt();
	body.headers[xide] = ideMd5;
	try {
		let result = await axios(body);
		let data = result.data;
		if (data.code == 100) {
			throw new Error(data.code)
		}
		if (data.code >= 200 && data.code < 400) {
			if (data.data) {
				let decStr = decryptAES(data.data);
				if (decStr.indexOf("{") != -1 && decStr.indexOf("}") != -1) {
					data.data = JSON.parse(decStr);
				} else if (decStr.indexOf("[") != -1 && decStr.indexOf("]") != -1) {
					data.data = JSON.parse(decStr);
				} else {
					data.data = decStr;
				}
			}
			if (callback != null) {
				callback(data);
				return;
			}
			return data;
		}
		if (data.code == 400) {
      ga.event("Error400",data.message)
			throw new Error(data.message)
		}
		if (data.code == 401) {
      ga.event("Error401","expired")
			throw new Error("The login session has expired, please log in again.")
		}
		if (data.code == 500) {
      ga.event("Error500",data.message)
			throw new Error(data.message)
		}
		throw new Error(data.message)
	} catch (err) {
		if (err.response && (err.response.status >= 500 || err.response.status >= 404)) {
			window.showErrorTips("服务维护中，请几分钟后再试")
			throw new Error("服务维护中，请几分钟后再试")
		} else {
			if (err.message == "Network Error") {
				window.showErrorTips("网络连接异常")
				throw new Error(err.message)
			} else if (err.message == 100) {
				throw new Error(err.message)
			} else {
				window.showErrorTips(err.message)
				throw new Error(err.message)
			}
		}

	}
}

function salt() {
	let time = new Date().getTime(); // ms
	let p = 1000000;
	let suf = time % p;
	let s = 1000;
	let z = Math.floor(suf / s);
	let t = suf % s;
	let id = commonUtil.getUuid().replace(/-/g, '');
	return fn1(z, 3) + id + fn1(t, 3);
}

function fn1(num, length) {
	return (num / Math.pow(10, length)).toFixed(length).substr(2);
}

// 创建AES解密器并设置密钥和IV
function decryptAES(ciphertext) {
	let key = "sda!@3SD3%2~wdsa";
	let iv = "33AB00C0B0Q00F12";
	let encryptedStr = CryptoJS.enc.Base64.parse(ciphertext);
	let srcs = CryptoJS.enc.Base64.stringify(encryptedStr);
	let decrypt = CryptoJS.AES.decrypt(srcs, CryptoJS.enc.Utf8.parse(key), {
		iv: CryptoJS.enc.Utf8.parse(iv),
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
	return decryptedStr.toString();
}